<template>
  <div ref="target" class="cart-sku">
    <div class="attrs" @click="toggle">
      <span class="ellipsis">{{ attrsText }}</span>
      <i class="iconfont icon-angle-down"></i>
    </div>
    <div v-if="visible" class="layer">
      <!-- 加载 -->
      <div v-if="loadingSku" class="loading"></div>
      <!-- 规格选择 -->
      <GoodsSku
        v-if="!loadingSku"
        :skuId="skuId"
        :skus="specsAndSkus.skus"
        :specs="specsAndSkus.specs"
        @onSpecChange="onSpecChange"
      />
      <XtxButton
        v-if="specsAndSkus"
        type="primary"
        size="mini"
        style="margin-left: 60px"
        @click="submitSku"
      >
        确定
      </XtxButton>
    </div>
  </div>
</template>
<script>
import useCartSku from '../../../hooks/cart/useCartSku'
import GoodsSku from '../../goods/components/GoodsSku'

export default {
  name: 'CartSku',
  components: { GoodsSku },
  props: {
    attrsText: {
      type: String,
      default: '',
    },
    skuId: {
      type: String,
      default: '',
    },
  },
  setup (props) {
    // 导入hooks
    const {
      visible,
      target,
      toggle,
      specsAndSkus,
      loadingSku,
      onSpecChange,
      submitSku,
    } = useCartSku(props)

    return {
      visible,
      target,
      toggle,
      specsAndSkus,
      loadingSku,
      onSpecChange,
      submitSku,
    }
  },
}
</script>
<style scoped lang="less">
.cart-sku {
  height: 28px;
  border: 1px solid #f5f5f5;
  padding: 0 6px;
  position: relative;
  margin-top: 10px;
  display: inline-block;
  .attrs {
    line-height: 24px;
    display: flex;
    span {
      max-width: 230px;
      font-size: 14px;
      color: #999;
    }
    i {
      margin-left: 5px;
      font-size: 14px;
    }
  }
  .layer {
    position: absolute;
    left: -1px;
    top: 40px;
    z-index: 10;
    width: 400px;
    border: 1px solid @xtxColor;
    box-shadow: 2px 2px 4px lighten(@xtxColor, 50%);
    background: #fff;
    border-radius: 4px;
    font-size: 14px;
    padding: 20px;
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      border-left: 1px solid @xtxColor;
      border-top: 1px solid @xtxColor;
      position: absolute;
      left: 12px;
      top: -8px;
      background: #fff;
      transform: scale(0.8, 1) rotate(45deg);
    }
    .loading {
      height: 224px;
      background: url(../../../assets/images/loading.gif) no-repeat center;
    }
  }
}
</style>
